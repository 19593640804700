.carousel-container {
    width: 100%; /* 100% of the viewport width */
    margin: 0; /* No margin */
    padding: 0; /* No padding */
    position: relative;
    overflow: hidden;
  }
  
  .slick-slide img {
    width: 100vw; 
    height: 500px; 
    object-fit: cover; /* Ensures the image covers the container */
  }
  
  .slick-prev, .slick-next {
    z-index: 1;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent arrows */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    top: 50%;
  }
  
  .slick-prev:hover, .slick-next:hover {
    background-color: rgba(0, 0, 0, 0.9); /* Darker on hover */
  }
  
  .slick-prev:before, .slick-next:before {
    font-size: 25px;
    color: white;
  }
  
  .slick-dots {
    bottom: 20px;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .slick-dots li.slick-active button:before {
    color: white;
  }
  
  .caption {
    position: absolute;
    bottom: 40px;
    left: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 1.2rem;
    animation: fadeInUp 0.8s ease-in-out;
  }
  
  .caption h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .caption p {
    font-size: 1rem;
    opacity: 0.85;
  }
  
  /* Fade-in animation for captions */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  