.header-bg {
  /* background: url(https://pmayg.nic.in/netiay/images/top-bg.png) repeat; */
  padding: 20px 0;
}

.emblem-text {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.emblem-text .main-title {
  font-size: 36px; /* Larger font for main title */
  font-weight: bold;
  color: #333;
  display: block;
}

.emblem-text .sub-title {
  font-size: 24px; /* Bigger font size for "GOVERNMENT OF INDIA" */
  font-weight: 700;
  margin-top: 5px;
  color: #007bff; /* Blue color for the 'GOVERNMENT OF INDIA' text */
  display: block;
}

.mid-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-logo {
  width: 110px;
  height: auto;
  margin: 0 10px;
}

/* Rotating Image (3D Effect) */
.rotating-image img {
  animation: rotateImage 5s infinite linear;
}

@keyframes rotateImage {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* Responsive styling */
@media (max-width: 768px) {
  .main-title {
    font-size: 28px;
  }

  .sub-title {
    font-size: 20px;
  }

  .side-logo {
    width: 90px;
  }
}

@media (max-width: 576px) {
  .main-title {
    font-size: 24px;
  }

  .sub-title {
    font-size: 18px;
  }

  .side-logo {
    width: 80px;
  }
}
/* TopBelow.css */

.custom-navbar {
  background-color: #f8f9fa; /* Light background color */
  border-bottom: 1px solid #ddd;
  padding: 4px 0;
  display: block; /* Override flex display if set by default */
}

.navbar-inner {
  padding: 0;
}

.navbar-brand {
  display: block;
  margin: 0 auto;
}

.navbar .row {
  display: flex; /* Ensure the row uses flexbox for alignment */
  justify-content: center; /* Center align the children items */
  align-items: center;
}

.navbar .col-6,
.navbar .col-sm-4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .navbar-brand img {
  max-width: 100%; /* Ensures responsive image scaling */
  height: auto;
}

.navbar .text-center {
  text-align: center;
}

.navbar .text-sm-left {
  text-align: left;
}

.navbar .text-sm-right {
  text-align: right;
}

/* Adjustments for smaller screens */
@media (max-width: 767px) {
  .navbar .col-6,
  .navbar .col-sm-4 {
    text-align: center;
  }

  .navbar .navbar-brand h1 {
    font-size: 24px;
  }

  .navbar .navbar-brand h5 {
    font-size: 14px;
  }
}

/* Additional CSS fixes for spacing and layout */
@media (max-width: 480px) {
  .navbar .navbar-brand img {
    max-height: 50px; /* Adjust logo sizes on small screens */
  }

  .navbar .navbar-brand h5 {
    font-size: 10px;
  }

  .navbar .navbar-brand h1 {
    font-size: 18px;
  }
}
/* Custom CSS Animations */
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInCenter {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounceRight {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(5px);
  }
  80% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Apply fade-in effect for the Left Logo */
.custom-navbar .col-6.col-sm-4.text-center.text-sm-left {
  animation: fadeInLeft 1.5s ease-out forwards;
  opacity: 0;
}

/* Apply fade-in and slide-up effect for the Center Content */
.custom-navbar .col-12.col-sm-4.text-center {
  animation: fadeInCenter 1.5s ease-out 0.5s forwards;
  opacity: 0;
}

/* Apply bounce animation for the Right Logo */
.custom-navbar .col-6.col-sm-4.text-center.text-sm-right img {
  animation: fadeInLeft 1.5s ease-out backwards;
  /* opacity: 0; */
}
