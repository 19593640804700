/* Container for the vertical scrolling */
.scrolling-container {
  height: 100px;  /* Adjust the height as needed */
  overflow: hidden; /* Hide the overflowing content */
  position: relative;
}

/* The content that will scroll */
.scrolling-content {
  display: flex;
  flex-direction: column; /* Stack the items vertically */
  animation: scroll-up 10s linear infinite; /* Apply the scrolling animation */
}

/* Styling for each h6 element */
.scrolling-content h6 {
  margin: 10px 0;  /* Space between each element */
}

/* Infinite scrolling animation */
@keyframes scroll-up {
  0% {
    transform: translateY(0);  /* Start from the top */
  }
  50% {
    transform: translateY(-100%); /* Scroll the first copy of content up */
  }
  /* 100% {
    transform: translateY(-200%);
  } */
}

/* Optional: Add a duplicate set of content to create a seamless effect */
.scrolling-content::after {
  content: "";
  display: block;
  height: 100%; /* Makes sure the content loops seamlessly */
  visibility: hidden; /* Hides this extra content, as it's not visible */
}


.logoo{
    margin-top: -68px ;
    margin-left: 110px;
    width : 20%;
    margin-bottom : 5px;

}

.custom-heading {
    font-family: 'Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif'; /* Font family */
    text-transform: uppercase; 
    letter-spacing: 1px; /* Spacing between letters */
    position: relative; /* For pseudo-elements */
    display: inline-block; /* Allows padding and margin adjustments */
    padding: 0 20px; /* Horizontal padding to create space */
    transition: color 0.3s ease; /* Smooth color transition on hover */  }
           
  .department {
    background-image : url('./Schemesbg/department_bg.jpg');
    background-size : cover;
    width : 100%;
    padding : 0;
    }
    button{
        color: #fff;
    }
                 
    .subheading {
    font-size : 20px;
    font-weight : 500;
    color : green;
    }
                 
    .department a {
    text-decoration : none;
    }
                 
    .department h2 {
    font-size : 40px;
    }
                 
                 
    .squre_1 {
      background-image: url("./Schemesbg/squre_1.png");
      display: flex;
      flex-direction: column;
      background-size: cover; /* Or you can adjust it based on your preference */
      background-repeat: no-repeat;
      background-position: center;
      padding: 10% 10% 5% 10%;
      text-align: left;
      border-radius: 15px; /* This adds rounded corners to the div and background image */
  }
                   
    .squre_2 {
    background-image : url("./Schemesbg/squre_2.png");
    display: flex;
    flex-direction: column;
    background-size: cover; /* Or you can adjust it based on your preference */
    background-repeat: no-repeat;
    background-position: center;
    padding: 10% 10% 5% 10%;
    text-align: left;
    border-radius: 15px; /* This adds rounded corners to the div and background image */
  }
                 
    .squre_3 {
    background-image : url("./Schemesbg/squre_3.png");
    display: flex;
    flex-direction: column;
    background-size: cover; /* Or you can adjust it based on your preference */
    background-repeat: no-repeat;
    background-position: center;
    padding: 10% 10% 5% 10%;
    text-align: left;
    border-radius: 15px; /* This adds rounded corners to the div and background image */
  }
                 
    .squre_4 {
    background-image : url("./Schemesbg/squre_4.png");
    display: flex;
    flex-direction: column;
    background-size: cover; /* Or you can adjust it based on your preference */
    background-repeat: no-repeat;
    background-position: center;
    padding: 10% 10% 5% 10%;
    text-align: left;
    border-radius: 15px; /* This adds rounded corners to the div and background image */
  }
                 
    .squre_5 {
    background-image : url("./Schemesbg/squre_5.png");
    display: flex;
    flex-direction: column;
    background-size: cover; /* Or you can adjust it based on your preference */
    background-repeat: no-repeat;
    background-position: center;
    padding: 10% 10% 5% 10%;
    text-align: left;
    border-radius: 15px; /* This adds rounded corners to the div and background image */
  }
                 
    .squre_6 {
    background-image : url("./Schemesbg/squre_6.png");
    display: flex;
    flex-direction: column;
    background-size: cover; /* Or you can adjust it based on your preference */
    background-repeat: no-repeat;
    background-position: center;
    padding: 10% 10% 5% 10%;
    text-align: left;
    border-radius: 15px; /* This adds rounded corners to the div and background image */
  }
                 
         
    .squre_1:hover,
.squre_2:hover,
.squre_3:hover,
.squre_4:hover,
.squre_5:hover,
.squre_6:hover {
  transform: scale(1.05) rotateZ(0deg); 
  box-shadow: 0 8px 30px rgba(164, 164, 164, 0.7); /* Stronger shadow on hover */
}
    .squre_1 .logo, .squre_2 .logo, .squre_3 .logo, .squre_4 .logo, .squre_5 .logo, .squre_6 .logo, .squre_7 .logo {
    width : 25%;
    margin-bottom : 10px;
    
    }
                 
    .squre_1 p, .squre_2 p, .squre_3 p, .squre_4 p, .squre_5 p, .squre_6 p, .squre_7 p {
    font-size : 20px;
    font-weight : 600;
    flex : 1;
    display : flex;
    margin : 10px 0;
    }
                 
    .squre_1 button {
    width : 70%;
    padding: 5px;
    height : 25px;
    border-radius : 22px;
    background : #ea719e;
    border : none;
    display : flex;
    justify-content : space-between;
    align-items : center;
    transition : 0.3s;
    }
                 
    .squre_1 button:hover {
    background-color : white;
    border : #ea719e solid 1px;
    transform : scale(1.1);
    }
                 
    .squre_1 button:hover div {
    background : #ea719e;
    color : white;
    }
                 
    .squre_1 button:hover .fa-angle-double-right {
    color : #ea719e;
    }
                 
    .squre_2 button {
      width : 70%;
      padding: 5px;
      height : 25px;
      border-radius : 22px;
    background : #dc926a;
    border : none;
    display : flex;
    justify-content : space-between;
    align-items : center;
    transition : 0.3s;
    }
                 
    .squre_2 button:hover {
    background-color : white;
    border : #dc926a solid 1px;
    transform : scale(1.1);
    }
                 
    .squre_2 button:hover div {
    background : #dc926a;
    color : white;
    }
                 
    .squre_2 button:hover .fa-angle-double-right {
    color : #dc926a;
    }
                 
    .squre_3 button {
      width : 70%;
      padding: 5px;
      height : 25px;
      border-radius : 22px;
    background : #7a94ce;
    border : none;
    display : flex;
    justify-content : space-between;
    align-items : center;
    transition : 0.3s;
    }
                 
    .squre_3 button:hover {
    background-color : white;
    border : #7a94ce solid 1px;
    transform : scale(1.1);
    }
                 
    .squre_3 button:hover div {
    background : #7a94ce;
    color : white;
    }
                 
    .squre_3 button:hover .fa-angle-double-right {
    color : #7a94ce;
    }
                 
    .squre_4 button {
      width : 70%;
      padding: 5px;
      height : 25px;
      border-radius : 22px;
    background : #cdbd6b;
    border : none;
    display : flex;
    justify-content : space-between;
    align-items : center;
    transition : 0.3s;
    }
                 
    .squre_4 button:hover {
    background-color : white;
    border : #cdbd6b solid 1px;
    transform : scale(1.1);
    }
                 
    .squre_4 button:hover div {
    background : #cdbd6b;
    color : white;
    }
                 
    .squre_4 button:hover .fa-angle-double-right {
    color : #cdbd6b;
    }
                 
    .squre_5 button {
      width : 70%;
      padding: 5px;
      height : 25px;
      border-radius : 22px;
    background : #5bc559;
    border : none;
    display : flex;
    justify-content : space-between;
    align-items : center;
    transition : 0.3s;
    }
                 
    .squre_5 button:hover {
    background-color : white;
    border : #5bc559 solid 1px;
    transform : scale(1.1);
    }
                 
    .squre_5 button:hover div {
    background : #5bc559;
    color : white;
    }
                 
    .squre_5 button:hover .fa-angle-double-right {
    color : #5bc559;
    }
                 
    .squre_6 button {
      width : 70%;
      padding: 5px;
      height : 25px;
      border-radius : 22px;
    background : #a85dbc;
    border : none;
    display : flex;
    justify-content : space-between;
    align-items : center;
    transition : 0.3s;
    }
                 
    .squre_6 button:hover {
    background-color : white;
    border : #a85dbc solid 1px;
    transform : scale(1.1);
    }
                 
    .squre_6 button:hover div {
    background : #a85dbc;
    color : white;
    }
                 
    .squre_6 button:hover .fa-angle-double-right {
    color : #a85dbc;
    }
                 
    .squre_7 button {
    width : 100%;
    height : 40px;
    border-radius : 22px;
    background : #a5c387;
    border : none;
    display : flex;
    justify-content : space-between;
    align-items : center;
    transition : 0.3s;
    }
                 
    .squre_7 button:hover {
    background-color : white;
    border : #a5c387 solid 1px;
    transform : scale(1.1);
    }
                 
    .squre_7 button:hover div {
    background : #a5c387;
    color : white;
    }
                 
    .squre_7 button:hover .fa-angle-double-right {
    color : #a5c387;
    }
                 
    .squre_1 button div, .squre_2 button div, .squre_3 button div, .squre_4 button div, .squre_5 button div, .squre_6 button div, .squre_7 button div {
    width : 50%;
    height : 31px;
    font-size : 15px;
    font-weight : 500;
    border-radius : 16px;
    display : flex;
    align-items : center;
    justify-content : center;
    }











    .modern-heading {
        font-family: 'Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif'; /* Font family */
        font-size: 1.2em; /* Font size for the heading */
        margin: 0; /* Remove default margin */
        text-transform: uppercase; /* Uppercase letters for modern feel */
        letter-spacing: 3px; /* Increased letter spacing */
        position: relative; /* For positioning pseudo-elements */
        display: inline-block; /* To apply padding and margin adjustments */
        padding: 0 10px; /* Horizontal padding to create space */
        background: linear-gradient(45deg, #ff0000, #2172ff, #55e157); /* Gradient color */
        background-clip: text; /* Clip background to text */
        -webkit-background-clip: text; /* For Safari compatibility */
        color: transparent; /* Hide original text color */
        animation: textGradient 3s linear infinite; /* Gradient animation */
      }
      
      .modern-heading span::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -8px; /* Adjust the distance below the text */
        width: 100%; /* Full width of the text */
        height: 4px; /* Thickness of the underline */
        background-color: #2a9df4; /* Color of the underline */
        transform: scaleX(0); /* Start hidden */
        transition: transform 0.3s ease; /* Animation for the underline */
      }
      
      /* Underline animation on hover */
      .modern-heading:hover span::after {
        transform: scaleX(1); /* Show underline on hover */
      }
      
      /* Gradient animation keyframes */
      @keyframes textGradient {
        0% {
          background-position: 0% 50%; /* Start position */
        }
        100% {
          background-position: 100% 50%; /* End position */
        }
      }
      
      /* Fade-in animation for heading */
      @keyframes fadeIn {
        0% {
          opacity: 0; /* Start hidden */
          transform: translateY(20px); /* Start from below */
        }
        100% {
          opacity: 1; /* Fully visible */
          transform: translateY(0); /* Move to original position */
        }
      }
      
      /* Apply fade-in animation on load */
      .modern-heading {
        animation: fadeIn 1s forwards; /* Fade in animation */
      }