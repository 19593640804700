.table-controls {
    display: flex;
    justify-content: space-between;
  }
  
  .search-input {
    width: 50%;
  }
  
  
  .btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: white;
  }
  
  .btn-info:hover {
    background-color: #138496;
    border-color: #117a8b;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  /* General Styles */
.ngo-container {
  background-color: #f4f7fb;
  padding: 20px 30px;
}

/* Sidebar */
.sidebar {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar h4 {
  font-weight: 600;
  font-size: 1.5rem;
}

/* Form Control */
.form-control {
  border-radius: 8px;
  padding: 12px;
  font-size: 1rem;
}

/* Buttons */
.btn-custom {
  font-size: .8rem;
  font-weight: 400;
  margin: 12px 20px;

  padding: 12px 20px;
  border-radius: 8px;
}

.btn-custom:hover {
  opacity: 0.9;
}

/* Table */
.custom-table {
  margin-top: 20px;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 12px 18px;
  text-align: center;
  font-size: 1rem;
  vertical-align: middle;
}

.custom-table th {
  background-color: #f7f9fc;
  font-weight: 500;
  color: #555;
}

.table-row:hover {
  background-color: #e6f7ff;
  cursor: pointer;
}

/* Table Container */
.table-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.table-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #4e73df;
}

.table-container .spinner-border {
  margin-top: 20px;
}
