/* Light Theme */
body.light {
  --background-color: #ffffff;
  --text-color: #000000;
  --link-color: #007bff;
  --button-bg-color: #131313;
  --button-text-color: #ffffff;
}

/* Dark Theme */
body.dark {
  --background-color: #1e2a35;
  --text-color: #f1f1f1;
  --link-color: #1e90ff;
  --button-bg-color: #e1e1e1;
  --button-text-color: #181818;
}

/* Apply the variables */
body {
  background-color: var(--background-color);
  color: var(--text-color);
}

a {
  color: var(--link-color);
}

button {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
}

body.default {
  font-size: 16px; /* Default font size */
}

body.large {
  font-size: 20px; 
}

body.small {
  font-size: 14px; /* Smaller font size */
}

.large .topbar-area {
  font-size: 20px;
}

.small .topbar-area {
  font-size: 14px;
}
.row {
  display : flex;
  flex-wrap : wrap;
  }
               
  .column {
  flex : 1;
  margin : 0.5rem;
  padding : 1rem;
  }


  