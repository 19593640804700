/* AboutUs.css */
#about-us-section {
  padding: 20px 0;
  position: relative;
}

.section-header {
  text-align: center; /* Centers the heading */
  font-size: 2rem; /* Set a reasonable base font size */
  font-weight: bold; /* Make the text bold for emphasis */
  margin-bottom: 30px; /* Adds some space below the heading */
  color: #333; /* You can customize the color as needed */
}
.section-header h2 {
  text-align: center;  /* Center the heading */
  font-size: 2rem;      /* Adjust font size */
  font-weight: bold;    /* Make it bold */
  margin: 0;            /* Remove default margin */
}

@media (max-width: 768px) {
  .section-header {
    font-size: 1.5rem; /* Adjust font size on smaller screens */
  }
}

@media (max-width: 480px) {
  .section-header {
    font-size: 1.2rem; /* Even smaller font size for extra small screens */
    margin-bottom: 20px; /* Reduce bottom margin on very small screens */
  }
}

.section-title {
  font-size: 2.75rem;
  color: #2c3e50;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  animation: fadeIn 1s ease-out forwards;
}

.section {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.section:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.section-heading {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2980b9;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.section p, .section ul, .section ol {
  font-size: 1.05rem;
  color: #34495e;
  line-height: 1.6;
  margin-bottom: 20px;
}

.section ul, .section ol {
  padding-left: 20px;
}

.scroll-container {
  overflow: hidden;
  position: relative;
  height: 200px;
}

.scroll-content {
  display: flex;
  flex-direction: column;
  animation: scrollAnimation 12s linear infinite;
}

.scroll-content li {
  font-size: 1.1rem;
  padding: 5px 0;
  transition: all 0.3s ease;
}

.scroll-content li:hover {
  color: #2980b9;
  font-weight: bold;
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* Responsive Media Queries */
@media (max-width: 992px) {
  #about-us-section {
    padding: 60px 0;
  }

  .section-title {
    font-size: 2.5rem;
  }

  .section-heading {
    font-size: 1.75rem;
  }

  .section p, .section ul, .section ol {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  #about-us-section {
    padding: 40px 0;
  }

  .section-title {
    font-size: 2.2rem;
  }

  .section-heading {
    font-size: 1.6rem;
  }

  .section p, .section ul, .section ol {
    font-size: 1rem;
  }

  .scroll-container {
    height: 180px;
  }
}

@media (max-width: 576px) {
  #about-us-section {
    padding: 30px 0;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .section-heading {
    font-size: 1.4rem;
  }

  .section p, .section ul, .section ol {
    font-size: 0.95rem;
  }

  .scroll-container {
    height: 160px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
