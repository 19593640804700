/* ContactUs.css */

/* Overall container styling */
/* .container {
    max-width: 1200px;
    margin-top: 50px;
  } */
  
  .card {
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Card Header */
  .card-body h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
  }
  
  .card-body {
    padding: 30px;
  }
  
  /* Contact Info Styling */
  .contact-info p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }
  
  .contact-info span {
    margin-left: 10px;
    font-size: 1rem;
  }
  
  .contact-info a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  /* Icons styling */
  .contact-info i {
    font-size: 1.5rem;
    color: #007bff;
    margin-right: 10px;
  }
  
  /* Card hover effect */
  .card:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
    transition: all 0.3s ease-in-out;
  }
  
  /* Styling for Map */
  iframe {
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-info p {
      font-size: 0.9rem;
    }
  
    .card-body {
      padding: 20px;
    }
  
    .card-body h2 {
      font-size: 1.8rem;
    }
  
    iframe {
      height: 250px;
    }
  
    .col-md-6 {
      margin-bottom: 20px;
    }
  }
  
  /* Smooth fade-in animation for contact info */
  .contact-info p {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s forwards;
  }
  
  .contact-info p:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .contact-info p:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .contact-info p:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .contact-info p:nth-child(4) {
    animation-delay: 0.8s;
  }
  
  /* Animation keyframes */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  