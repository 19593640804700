.topbar-area {
    background: #73bbff;
    color: #ffffff;
    padding: 5px 0;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    text-decoration: none;
}

.topbar-area .topbar-menu ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.topbar-area .topbar-menu ul li {
    margin: 0 5px;
}

.topbar-area .topbar-menu ul li a {
    text-decoration: none;
    color: #131313;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    transition: color 0.3s ease;
}

.topbar-area .topbar-menu ul li a:hover {
    color: #e9ecef;
    text-decoration: none;
}

.topbar-area .topbar-social .social-area {
    display: flex;
    align-items: center;
}

.topbar-area .topbar-date {
    color: #000000;
    font-size: 14px;
    border-right: 1px solid #495057;
    padding-right: 20px;
    margin-right: 15px;
}

.topbar-area .topbar-date i {
    margin-right: 8px;
}

.topbar-area .social-area a {
    color: #24050c;
    font-size: 18px;
    margin: 0 8px;
    transition: color 0.3s ease;
}

.topbar-area .social-area a:hover {
    color: #e9ecef;
}

.topbar-area .day-night-toggle, 
.topbar-area .text-size-toggle {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.topbar-area .toggle-label {
    font-size: 12px;
    font-weight: 600;
    color: #24050c;
    text-transform: uppercase;
    margin-right: 8px;
}

.topbar-area .mode-toggle-btn, 
.topbar-area .text-size-btn {
    background-color: #131313;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 12px;
    margin-right: 5px;
    transition: background-color 0.3s ease;
}

.topbar-area .mode-toggle-btn:hover, 
.topbar-area .text-size-btn:hover {
    background-color: #495057;
}

.topbar-area .skip-to-content {
    font-size: 12px;
    color: #007bff;
    text-transform: uppercase;
    margin-left: 15px;
    text-decoration: none;
}

.topbar-area .skip-to-content:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .topbar-area .topbar-menu ul {
        /* flex-direction: column; */
        align-items: flex-start;
    }

    .topbar-area .topbar-menu ul li {
        margin: 5px 0;
    }

    .topbar-area .topbar-social {
        flex-direction: column;
        align-items: flex-start;
    }

    .topbar-area .topbar-date {
        margin-right: 0;
        margin-bottom: 10px;
        padding-right: 0;
        border-right: none;
    }

    .topbar-area .day-night-toggle, 
    .topbar-area .text-size-toggle {
        margin-left: 0;
        margin-top: 10px;
        flex-direction: row;
    }

    .topbar-area .skip-to-content {
        margin-left: 0;
        margin-top: 10px;
    }
}