  
  h3 {
    font-family: 'Arial', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #333;
  }
  
  .image-card, .video-card {
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .image-card:hover, .video-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .image-card img, .video-card img {
    border-radius: 8px;
  }
  
  .image-card .card-title, .video-card .card-title {
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
  }
  
  .modal-body {
    text-align: center;
  }
  
  .video-gallery video {
    width: 100%;
    height: auto;
  }
  