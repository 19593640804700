
  /* Sidebar Styles */
  
  /* Main Content */
  .main-content {
    flex: 1;
    padding: 1rem;
  }
  
  /* Styled Table */
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1rem;
    text-align: left;
  }
  
  .styled-table th, .styled-table td {
    padding: 12px 15px;
  }
  
  .styled-table th {
    background: #34495e;
    color: white;
  }
  
  .styled-table tr:nth-child(even) {
    background: #f3f3f3;
  }
  
  .styled-table tr:hover {
    background: #ddd;
  }
  
  /* Error Message */
  .error-message {
    color: red;
    font-weight: bold;
  }
