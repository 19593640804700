/* Navbar styling */
.navbarr {
  background-color: #73bbff; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  transition: background-color 0.3s ease-in-out;
  position: relative; /* Ensure proper positioning */
}

/* Centering the menu */
.menu {
  display: flex;
  gap: 2rem;
  justify-content: center; /* Center menu items */
  flex-grow: 1; /* Allow menu to take up available space */
}

.logo {
  font-size: 1.8rem;
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
  position: absolute;
  left: 2rem; /* Keep logo aligned to the left */
}

.logo:hover {
  color: #FFD700; /* Gold color on hover */
}

.hamburger {
  font-size: 2rem;
  color: white;
  cursor: pointer;
  display: none; /* Hidden by default on desktop */
  position: absolute;
  right: 2rem; /* Align hamburger to the right */
}

/* Center the menu items */
.menu-item {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.1rem 1rem;
  border-radius: 20px; /* Rounded buttons */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.menu-item:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.1); /* Button grows slightly on hover */
}

.icon {
  font-size: 1.2rem; /* Adjust icon size */
}

/* Heading after Navbar */
.heading {
  display: block;  
  width: 100%;
  text-align: center;  /* Center align */
  font-size: 1.5rem;  /* Reduce font size */
  font-weight: bold;
  margin-top: 20px;
  color: #003366; /* Dark Blue color */
}

/* Responsive Navbar for mobile devices */
@media (max-width: 768px) {
  .hamburger {
    display: block; /* Show hamburger icon on mobile */
  }

  .menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #55a7ffb4;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%); /* Initially hide the menu off-screen */
    transition: transform 0.4s ease-in-out, background-color 0.4s ease;
    z-index: -1; /* Ensure the menu stays behind content when hidden */
  }

  .menu.open {
    transform: translateY(0); /* Slide down when opened */
    z-index: 999; /* Bring the menu to the front */
  }

  .menu-item {
    padding: 0.7rem;
    width: 100%;
    text-align: center;
    font-size: 1.5rem; /* Larger text for mobile */
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .menu-item:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: scale(1.05); /* Slight scale effect on hover */
  }
}
