.footer {
    background-image : url("./footer.png");
    font-size : 20px;
    padding-bottom : 10px;
    margin-top : 20px;
    padding : 10px;
    background-color : #86B6F6;
    }
                 
    .footer-row-2 {
    overflow : hidden;
    }
                 
    .footer-row-2 ul li {
    padding : 0 5px;
    margin-right : 5px;
    text-decoration : none;
    list-style-type : none;
    border-right : 1px solid rgb(134 134 134);
    }
                 
    .footer .copyright a {
    font-weight : 600;
    }
                 
    .lh-35 {
    line-height : 35px;
    }
                 
    .logo {
    font-weight : 600;
    letter-spacing : 1px;
    }
                 
    .logo-login {
    width : 300px;
    }
                 
    .logo h3 {
    color : #000;
    }
                 
    .widget p {
    padding : 2px 4px 2px 2px;
    color : #000;
    font-size : 14px;
    }
                 
    .widget .divider {
    height : 3px;
    }
    .logo {
        width: 50px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
      
      .logo-text {
        font-size: 18px;
      }
      .widget {
        text-align : left;
        }
                     
        .widget-title {
        font-size : 18px;
        font-weight : bold;
        margin-bottom : 15px;
        }
                     
        .quick-links-list {
        columns : 2;
        column-gap : 20px;
        padding-left : 0;
        }
                     
        .quick-link {
        color : #333;
        text-decoration : none;
        transition : color 0.3s ease;
        justify-content : left;
        font-size : 12px;
        }
                     
        a, .a {
        color : #000;
        }
                     
        .quick-link:hover {
        color : #007bff;
        text-decoration : underline #009879;
        }
                     
        .quick-link:hover {
        transform : translateY(-3px);
        }
                     
        .widget h4 {
        font-size : 1.3rem;
        color : #000;
        text-decoration : none;
        text-align : left;
        display : block;
        }
                     
        .widget .footer-menu a {
        color : #000;
        font-size : 14px;
        text-decoration : none;
        text-align : left;
        display : block;
        }
                     
        .widget .footer-menu a:hover {
        color : #e12454;
        }
                     
        .footer-contact-block span {
        font-weight : 400;
        color : #000;
        }
                     
        .footer-contact-block i {
        font-size : 20px;
        }
                     
        .footer-btm {
        border-top : 1px solid rgb(0 0 0 / 6%);
        }
                     
        .footer-socials li a {
        width : 45px;
        height : 45px;
        background : #6F8BA4;
        color : #fff;
        display : inline-block;
        text-align : center;
        border-radius : 100%;
        padding-top : 12px;
        }
                     
        .widget-contact h6 {
        font-weight : 500;
        margin-bottom : 18px;
        }
                     
        .widget-contact h6 i {
        color : #e12454;
        }
                     
        .footer-text:hover {
        font-size : 14px;
        color : #d8ffff;
        text-decoration : none;
        }
                     
        .timeline {
        position : relative;
        max-width : 1200px;
        margin : 0 auto;
        padding : 20px 0;
        }
                     
        .timeline::after {
        content : '';
        position : absolute;
        width : 6px;
        background-color : #ddd;
        top : 0;
        bottom : 0;
        left : 50%;
        margin-left : -3px;
        }
                     
        .timeline-container {
        padding : 10px 40px;
        position : relative;
        background-color : inherit;
        width : 50%;
        }